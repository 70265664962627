



















import { Component, Vue } from 'vue-property-decorator'
import { themeConfig } from '@/config/themeConfig'

@Component({
  components: {
    BackLinks: () => import('./Backlinks.vue')
  }
})
export default class AppFooter extends Vue {
  name = themeConfig.app.name
}
