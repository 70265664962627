


























































import { Component,  Vue } from 'vue-property-decorator'
import Ripple from 'vue-ripple-directive'

@Component({
  directives: {
    Ripple
  }
})
export default class AppBreadcrumb extends Vue {

}
