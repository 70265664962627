export default [
  {
    'header': 'OLYMPUS'
  },
  {
    title: 'DOWNLOAD_LAUNCHER',
    route: 'download',
    icon: 'DownloadIcon'
  },
  {
    title: 'LEADERBOARD',
    route: 'leaderboards',
    icon: 'BarChart2Icon'
  },
  {
    title: 'Support',
    route: 'support',
    icon: 'HelpCircleIcon'
  },
  {
    'header': 'PROFILE_SECTION_TITLE'
  },
  {
    title: 'PROFILE',
    route: 'profile',
    icon: 'UserIcon',
  },
  {
    title: 'SETTINGS',
    route: 'settings',
    icon: 'SettingsIcon'
  },
  {
    title: 'LOGS',
    route: 'logs',
    icon: 'FileTextIcon'
  },
  {
    title: 'COUPON_REDEEM',
    route: 'coupons',
    icon: 'TagIcon'
  },
  {
    'header': 'SHOP_SECTION_TITLE'
  },
  {
    title: 'SHOP',
    route: 'shop',
    icon: 'ShoppingBagIcon'
  },
  {
    title: 'BUY_OC',
    route: 'purchase',
    icon: 'DollarSignIcon'
  },
  {
    title: 'FORTUNE_WHEEL',
    route: 'fortune-wheel',
    icon: 'PieChartIcon'
  },
  {
    'header': 'GAME MASTERS'
  },
  {
    title: 'GM Panel',
    route: 'gm',
    icon: 'LayoutIcon'
  },
  /* {
    title: 'Character Logs',
    route: 'gm',
    icon: 'FileTextIcon'
  },
  {
    title: 'Find Alts',
    route: 'find-alts',
    icon: 'UsersIcon'
  },
  {
    title: 'Punishment Logs',
    route: 'punishment-logs',
    icon: 'FileTextIcon',
  },
  /* {
    title: 'Refund Requests',
    route: 'refunds',
    icon: 'RotateCwIcon'
  }, */
  {
    'header': 'ADMINISTRATORS'
  },
  {
    title: 'Create Coupon',
    route: 'coupons-create',
    icon: 'GiftIcon'
  },
  {
    title: 'User List',
    route: 'users',
    icon: 'UsersIcon'
  },
  {
    title: 'News Management',
    route: 'news',
    icon: 'BookIcon'
  },
  {
    title: 'Account Notes',
    route: 'account-notes',
    icon: 'FileIcon'
  },
  {
    'header': 'ROOT'
  },
  {
    title: 'Settings',
    route: 'root-settings',
    icon: 'SettingsIcon'
  },
  {
    title: ' Logs',
    route: 'root-logs',
    icon: 'FileTextIcon'
  },
  {
    title: 'GameVersion Management',
    route: 'gameversions',
    icon: 'GitPullRequestIcon'
  },
  /* {
    title: 'Transactions',
    route: 'pending-transactions',
    icon: 'RotateCwIcon'
  }, */
]
