


















































import { Component, Prop, Vue } from 'vue-property-decorator'
import fillStore from '@/services/fillStore'
import { UserModule } from '@/store/modules'

@Component({
  components: {
    LanguageSwitcher: () => import('./components/LanguageSwitcher.vue'),
    DarkToggler: () => import('./components/DarkToggler.vue'),
    MoneyHandler: () => import('./components/MoneyHandler.vue'),
    NotificationDropdown: () => import('./components/NotificationDropdown.vue'),
    UserDropdown:() => import('./components/UserDropdown.vue')
  }
})
export default class Navbar extends Vue {
  @Prop() toggleVerticalMenuActive
  @UserModule.State currentUser

  fillStore = fillStore

  navbarIcons = [
    { name: 'PROFILE', icon: 'UserIcon', path: '/profile' },
    { name: 'SHOP', icon: 'ShoppingBagIcon', path: '/shop' },
    { name: 'DOWNLOAD_LAUNCHER', icon: 'DownloadIcon', path: '/download' },
    { name: 'Refresh my data', icon: 'RotateCwIcon', action: true }
  ]
}
